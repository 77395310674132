.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.validationErr {
  color: red;
}

.approved_cls {
  color: green;
}

.rejected_cls {
  color: red;
}

.swal2-title {
  color: #10161c !important;
}

.loginLogo {
  /* background: #11161e; */
  padding: 10px;
  border-radius: 13px;
}
.admin-login .form-control {
  background: #000;
  color: #fff;
}
.admin-login {
  background: #000 !important;
}
.admin-login .form-control:hover,
.admin-login .form-control:focus {
  background: #000;
}

/* .loginLogo img{
  width: 40%;
} */
.table_filter {
  float: right;
}

i.fa.fa-copy {
  color: rgb(169 115 2) 100%;
}

.as-react-table table thead {
  background: linear-gradient(88deg, #6672ed 0%, #790bd9 100%);
  color: #ffffff;
}
.pagination li.page-item {
  line-height: 5px;
}
.as-react-table {
  overflow-x: auto;
}
/* width */
.as-react-table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.as-react-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.as-react-table::-webkit-scrollbar-thumb {
  background: linear-gradient(88deg, #6672ed 0%, #790bd9 100%);
  border-radius: 10px;
}

/* Handle on hover */
.as-react-table::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(88deg, #6672ed 0%, #790bd9 100%);
}
.table-foot {
  margin-bottom: 11px;
}
p.fs-14.mb-0.fw-700.text-primary {
  color: #fff !important;
  font-weight: 600;
}
small.fs-10.mb-0.text-uppercase.text-mute {
  color: #fff !important;
}
span.dark-logo.text-center.text-decoration-none h2 {
  padding: 10px;
  background-image: linear-gradient(88deg, #6672ed 0%, #790bd9 100%);
  line-height: 106%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
span.light-logo.text-center.text-decoration-none h2 {
  padding: 10px;
  background-image: linear-gradient(88deg, #6672ed 0%, #790bd9 100%);
  line-height: 106%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.unblockbutton {
  background: linear-gradient(88deg, #6672ed 0%, #790bd9 100%);
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid #6672ed !important;
  padding: 0px 10px;
}
.blockbutton {
  background: transparent;
  color: red;
  border-radius: 5px;
  border: 1px solid red !important;
  padding: 0px 18px;
}

.table-scroll:-webkit-scrollbar {
  display: none !important;
}

.font-w-400 {
  font-weight: 400;
}
.w-full {
  width: 100%;
}
.w-half {
  width: 50%;
}
.asrt-page-length {
  display: none !important;
}
.css-lnmdiq5-menu {
  color: #000000 !important;
  background: #61dafb !important;
}
.newLevel::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.newLevel::-webkit-scrollbar-track {
  background: #ecebeb;
  border-radius: 5px;
}

.newLevel::-webkit-scrollbar-thumb {
  background: linear-gradient(2deg, #6672ed 0%, #790bd9 100%);
  border-radius: 5px;
}
.dtbJfk::-webkit-scrollbar {
  height: 10px !important;
}

.dtbJfk::-webkit-scrollbar-track {
  background: #ecebeb !important;
  border-radius: 5px !important;
}

.dtbJfk::-webkit-scrollbar-thumb {
  background: linear-gradient(2deg, #6672ed 0%, #790bd9 100%);
  border-radius: 5px;
}
.sc-dmcuvF::-webkit-scrollbar {
  height: 10px !important;
}

.sc-dmcuvF::-webkit-scrollbar-track {
  background: #ecebeb !important;
  border-radius: 5px !important;
}

.sc-dmcuvF::-webkit-scrollbar-thumb {
  background: linear-gradient(2deg, #6672ed 0%, #790bd9 100%);
  border-radius: 5px;
}

.rdt_TableHeadRow {
  background: linear-gradient(2deg, #6672ed 0%, #790bd9 100%);
  color: #fff;
}
.rdt_Table {
  border: 1px solid #dcdcdc;
}
/* Remove input number arrow */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn-group{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.btn-group .blockbutton{
  height: 25px;
  border-radius: 0;
}